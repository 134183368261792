@font-face {
    font-family: 'Rawline';
    src: local('Rawline'), url(./Rawline/rawline-900.ttf) format('truetype');
    font-weight: 900;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gordita';
    src: local('Gordita'), url(./Gordita/gordita-900.ttf) format('opentype');
    font-weight: 900;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gordita';
    src: local('Gordita'), url(./Gordita/gordita-700.ttf) format('opentype');
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gordita';
    src: local('Gordita'), url(./Gordita/gordita-500.ttf) format('opentype');
    font-weight: 500;
    font-display: swap;
  }

  
