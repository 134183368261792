.App {
  text-align: center;
  background-color: #FBFBFB;
  color: #FBFBFB;
  min-height: 100vh;
  scrollbar-width: thin;
  /*height: 100%; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  color: #FBFBFB;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:-internal-autofill-previewed {
  appearance: menulist-button;
  background-image: none !important;
  background-color: white !important;
  color: fieldtext !important;
}
