@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

body {
  margin: 0;
  background-color: #FBFBFB;
  min-width: 290px;
  color: #FFF; 
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: none;
  scrollbar-width: thin;

}
/*  padding-right: 0 !important; */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  min-height: 100vh; /* 100% */
  scrollbar-width: thin;
}

div#root {
  min-height: 100vh;
  background-color: #FBFBFB;
  scrollbar-width: thin;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
